import { spotifyMonetizationConstants } from '../constants/spotify_monetization';
import { spotifyMonetizationService } from '../services/spotify_monetization';
import { tracksService } from '../services/tracks';
import { spotifyMonetizationFormatter } from '../formatters/spotify_monetization';

export const spotifyMonetizationActions = {
	getSpotifyMonetization,
	getSpotifyMonetizationTotals,
	getSpotifyMonetizationTimeseries,
	getSpotifyMonetizationDates
};

function getSpotifyMonetization(params) {
	return (dispatch, getState) => {
		dispatch(request());
		return spotifyMonetizationService.getSpotifyMonetization(params)
			.then(
				spotifyMonetization => {
					let data = spotifyMonetizationFormatter.formatSpotifyMonetizationFlat(spotifyMonetization);
					//return tracksService.getMetadata(ids)
					return Promise.resolve()
					.then(metadata => {
						//const tracks = spotifyMonetizationFormatter.formatMetadata(data, metadata.data);
						const tracks = data;
						dispatch(success(tracks));	
						return tracks;
					}) 

					
				},
				error => {
					dispatch(failure('error'))
				}
			);
	};

	function request() { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_REQUEST } }
	function success(data) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_SUCCESS, data } }
	function failure(error) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_FAILURE, error } }
}

function getSpotifyMonetizationTotals(params) {
	return (dispatch, getState) => {
		dispatch(request());
		return spotifyMonetizationService.getSpotifyMonetizationTotals(params)
			.then(
				spotifyMonetization => {
					let data = spotifyMonetizationFormatter.formatSpotifyMonetizationTotals(spotifyMonetization);
					dispatch(success(data));	
					return data;
				},
				error => {
					dispatch(failure('error'))
				}
			);
	};

	function request() { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_REQUEST } }
	function success(data) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_SUCCESS, data } }
	function failure(error) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TOTALS_FAILURE, error } }
}

function getSpotifyMonetizationTimeseries(params) {
	return (dispatch, getState) => {
		dispatch(request());
		return spotifyMonetizationService.getSpotifyMonetizationTimeseries(params)
			.then(
				spotifyMonetization => {
					let data = spotifyMonetizationFormatter.formatSpotifyMonetizationTimeseries(spotifyMonetization);
					dispatch(success(data));	
					return data;
				},
				error => {
					dispatch(failure('error'))
				}
			);
	};

	function request() { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TIMESERIES_REQUEST } }
	function success(data) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TIMESERIES_SUCCESS, data } }
	function failure(error) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_TIMESERIES_FAILURE, error } }
}

function getSpotifyMonetizationDates() {
	return (dispatch, getState) => {
		dispatch(request());
		const globalFilter = getState().filter.global;
		return spotifyMonetizationService.getSpotifyMonetizationDates(globalFilter)
			.then(
				dates => {
					dates = spotifyMonetizationFormatter.formatSpotifyMonetizationDates(dates);
					dispatch(success(dates));	
					return data;
				},
				error => {
					dispatch(failure('error'))
				}
			);
	};

	function request() { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_DATES_REQUEST } }
	function success(dates) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_DATES_SUCCESS, dates } }
	function failure(error) { return { type: spotifyMonetizationConstants.GET_SPOTIFY_MONETIZATION_DATES_FAILURE, error } }
}
