//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';

export const spotifyMonetizationService = {
	getSpotifyMonetization,
	getSpotifyMonetizationTotals,
	getSpotifyMonetizationTimeseries,
	getSpotifyMonetizationDates
};

function getSpotifyMonetization(params){
	return config.live ? getSpotifyMonetizationLive(params) : getSpotifyMonetizationStatic();
}

function getSpotifyMonetizationLive(params) {
	const requestOptions = {
		method: 'GET',
		headers: AuthHeader()
	};
	
	let baseUrl = config.apis.v3_data+'/simple',
		qs = BuildQS(params.filtered);
		
	qs.query_name = 'spotify_monetization';
	qs.mode = 'table';
	
	qs.offset = params.page ? params.page*params.pageSize : 0;
	qs.row_limit = params.pageSize;
	
	let {sorted, filtered} = params;
	if(sorted !== undefined && sorted.length){
		sorted = sorted[0];
		qs.order_by = sorted.id;
		qs.order_dir = sorted.desc ? 'desc':'asc';
	}
	
	if(filtered !== undefined && filtered.length){
		for(let filter of filtered) {
			let value = filter.value;
			if(!value)
				continue;
			if(value instanceof Array) {
				value = value.join(',');
				if(!value)
					continue;
				qs[filter.id] = value;
			}
			else {
				qs[filter.id] = value;    
			}
		}            
	}

	
	qs.release_date = filtered.releaseDate;
	qs.months = filtered.months;
	qs.status = filtered.status;
	
	baseUrl += '?' + $.param(qs);

	return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function getSpotifyMonetizationStatic() {
	let baseUrl = `${config.apis.sales}/api/static/spotify_monetization.json`;
	return fetch(baseUrl).then(res=>res.json());
}

function getSpotifyMonetizationTotals(params){
	return config.live ? getSpotifyMonetizationTotalsLive(params) : getSpotifyMonetizationTotalsStatic();
}

function getSpotifyMonetizationTotalsLive(params) {
	const requestOptions = {
		method: 'GET',
		headers: AuthHeader()
	};
	
	let baseUrl = config.apis.v3_data+'/simple',
		qs = BuildQS(params.filtered);
		
	qs.query_name = 'spotify_monetization';
	qs.mode = 'main';
	
	let {filtered} = params;
	if(filtered !== undefined && filtered.length){
		for(let filter of filtered) {
			let value = filter.value;
			if(!value)
				continue;
			if(value instanceof Array) {
				value = value.join(',');
				if(!value)
					continue;
				qs[filter.id] = value;
			}
			else {
				qs[filter.id] = value;    
			}
		}            
	}

	
	qs.release_date = filtered.releaseDate;
	qs.months = filtered.months;
	qs.status = filtered.status;
	
	baseUrl += '?' + $.param(qs);

	return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function getSpotifyMonetizationTotalsStatic() {
	let baseUrl = `${config.apis.sales}/api/static/spotify_monetization_totals.json`;
	return fetch(baseUrl).then(res=>res.json());
}

function getSpotifyMonetizationTimeseries(params){
	return config.live ? getSpotifyMonetizationTimeseriesLive(params) : getSpotifyMonetizationTimeseriesStatic();
}

function getSpotifyMonetizationTimeseriesLive(params) {
	const requestOptions = {
		method: 'GET',
		headers: AuthHeader()
	};
	
	let baseUrl = config.apis.v3_data+'/simple',
		qs = BuildQS(params.filtered);
		
	qs.query_name = 'spotify_monetization';
	qs.mode = 'timeseries';
	
	let {filtered} = params;
	if(filtered !== undefined && filtered.length){
		for(let filter of filtered) {
			let value = filter.value;
			if(!value)
				continue;
			if(value instanceof Array) {
				value = value.join(',');
				if(!value)
					continue;
				qs[filter.id] = value;
			}
			else {
				qs[filter.id] = value;    
			}
		}            
	}

	
	qs.release_date = filtered.releaseDate;
	qs.months = filtered.months;
	qs.status = filtered.status;
	
	baseUrl += '?' + $.param(qs);

	return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function getSpotifyMonetizationTimeseriesStatic() {
	let baseUrl = `${config.apis.sales}/api/static/spotify_monetization_timeseries.json`;
	return fetch(baseUrl).then(res=>res.json());
}

function getSpotifyMonetizationDates(globalFilter){
	return config.live ? getSpotifyMonetizationDatesLive(globalFilter) : getSpotifyMonetizationDatesStatic();
}

function getSpotifyMonetizationDatesLive(globalFilter) {
	const requestOptions = {
		method: 'GET',
		headers: AuthHeader()
	};
	
	let baseUrl = config.apis.v3_data+'/simple',
		qs = BuildQS(globalFilter);
		
	qs.query_name = 'spotify_monetization_dates';
	baseUrl += '?' + $.param(qs);

	return fetch(baseUrl, requestOptions).then(res=>res.json());
}

function getSpotifyMonetizationDatesStatic() {
	let baseUrl = `${config.apis.sales}/api/static/spotify_monetization_dates.json`;
	return fetch(baseUrl).then(res=>res.json());
}


